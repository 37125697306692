import React from "react";
import "../css/contactus.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import { useState } from "react";

export default function ContactPage() {
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackClass, setFeedbackClass] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      message: e.target.message.value,
    };

    try {
      const response = await fetch(
        "https://www.alphacontraining.co.uk/sendcontactemail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (response.ok) {
        setFeedbackMessage("Your message has been sent successfully!");
        setFeedbackClass("success");
      } else {
        setFeedbackMessage(`Failed to send message: ${result.error}`);
        setFeedbackClass("error");
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFeedbackMessage("There was an error. Please try again.");
      setFeedbackClass("error");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Alpha Construction Training</title>
        <meta
          name="description"
          content="Get in touch with Alpha Construction Training for inquiries about our services, training programs, and more. Contact us today!"
        />
        <meta
          name="keywords"
          content="Alpha Construction Training, contact us, construction training, inquiries, training programs"
        />
        <meta name="author" content="Alpha Construction Training" />
      </Helmet>
      <Navbar />
      <div className="contact-container">
        <div className="contact-hero">
          <h1>Contact Us</h1>
        </div>

        <div className="contact-content">
          <div className="contact-form-section">
            <form className="contact-form" onSubmit={handleSubmit}>
              <h2>Send Us a Message</h2>
              {feedbackMessage && (
                <p className={`feedback-message ${feedbackClass}`}>
                  {feedbackMessage}
                </p>
              )}
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  placeholder="Enter your message"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="submit-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Message Sent!" : "Submit"}
              </button>
            </form>
          </div>

          <div className="contact-info-social">
            <div className="contact-information">
              <h2 className="contact-heading">Contact Details</h2>
              <ul>
                <li>
                  <strong>Email:</strong> info@alphacontraining.co.uk
                </li>
                <li>
                  <strong>Phone:</strong> +44 7475 510 591
                </li>
                <li>
                  <strong>Address:</strong> 86 High Street, West Bromwich, B70
                  6JW
                </li>
              </ul>
            </div>

            <h2>Follow Us</h2>
            <div className="social-media-links">
              <a
                href="https://www.facebook.com/yourpage"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-square"></i>
              </a>
              <a
                href="https://twitter.com/yourhandle"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <i className="fab fa-twitter-square"></i>
              </a>
              <a
                href="https://www.instagram.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram-square"></i>
              </a>
            </div>
          </div>

          <div className="location">
            <h2>Our Location</h2>
            <div className="map-container">
              <iframe
                title="Google Map Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2428.0454082923584!2d-1.9872995!3d52.514517299999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487097ff7b0c0027%3A0x7873444e982f6030!2s86%20High%20St%2C%20West%20Bromwich%20B70%206JW!5e0!3m2!1sen!2suk!4v1732490137200!5m2!1sen!2suk"
                width="100%"
                height="500"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
