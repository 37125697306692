import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSForwardTippingDumper() {
  return (
    <>
      <Helmet>
        <title>
          A09B CPCS Forward Tipping Dumper | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Master the operation of forward tipping dumpers with the CPCS A09B course at Alpha Construction Training. Gain industry-recognized certification today."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A09B CPCS Forward Tipping Dumper</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed to provide participants with the skills,
              knowledge, and confidence required to safely and efficiently
              operate forward tipping dumpers. The training adheres to CPCS
              standards and industry best practices, ensuring compliance with
              safety regulations and operational efficiency.
            </p>
            <br />
            <p>
              The course is suitable for both newcomers and experienced
              operators looking to formalize their skills with an
              industry-recognized qualification. Participants will gain
              practical and theoretical knowledge essential for working with
              dumpers in a range of construction environments.
            </p>
            <br />
            <p>
              Completing this course enhances career prospects and provides a
              pathway to further qualifications and career advancement within
              the construction industry.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the roles and responsibilities of a forward tipping
                dumper operator, including legal and safety obligations.
              </li>
              <li>
                Conduct pre-use inspections and routine maintenance to ensure
                safe and efficient operation.
              </li>
              <li>
                Learn proper mounting and dismounting techniques in compliance
                with health and safety guidelines.
              </li>
              <li>
                Develop skills to safely travel over different terrains, both
                loaded and unloaded, while maintaining balance and control.
              </li>
              <li>
                Master the correct procedures for loading, transporting, and
                discharging materials safely and efficiently.
              </li>
              <li>
                Understand hazard management, including awareness of overhead
                and underground services, avoiding rollovers, and securing the
                dumper when not in use.
              </li>
              <li>
                Gain practical experience through real-world scenarios,
                reinforcing operational best practices and risk assessment.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              Candidates will be evaluated through both theoretical and
              practical assessments to demonstrate their competency in dumper
              operation. The assessment includes:
            </p>
            <br />
            <ul>
              <li>
                A written or verbal theoretical test covering safety protocols,
                operational procedures, and industry regulations.
              </li>
              <li>
                A practical assessment evaluating pre-operational checks,
                maneuvering, loading, and unloading techniques.
              </li>
              <li>
                The CPCS technical test, which must be successfully completed to
                obtain certification.
              </li>
            </ul>
            <br />
            <p>
              Successful candidates will be certified as competent dumper
              operators, meeting industry standards and employer requirements.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon passing the assessment, participants will receive the CPCS
              Red Trained Operator Card, valid for two years. This card
              demonstrates foundational competency in operating forward tipping
              dumpers.
            </p>
            <br />
            <p>
              To advance their certification, candidates can undertake an NVQ
              Level 2 in Plant Operations. Successfully completing the NVQ and
              gaining sufficient experience will allow candidates to apply for
              the CPCS Blue Competence Card, which is valid for five years and
              renewable through reassessment.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Forward Tipping Dumper (A09B) Course provides
              comprehensive training in the safe and effective operation of
              forward tipping dumpers. The course combines theoretical
              instruction with practical exercises to ensure participants gain
              hands-on experience and confidence in handling these machines.
            </p>
            <br />
            <p>
              With a strong emphasis on safety, operational efficiency, and
              compliance, this course prepares candidates for real-world
              construction environments. Graduates will possess the skills
              needed to operate dumpers responsibly and efficiently.
            </p>
            <br />
            <p>
              Earning an industry-recognized CPCS certification enhances
              employment opportunities in the construction sector. Enroll today
              to take the next step in your career.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
