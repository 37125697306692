import "../css/home.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import npors from "../images/npors.png";
import nvq from "../images/nvq.jpg";
import cpcs from "../images/cpcs.webp";
import whoAreWe from "../images/constructionsite.png";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";

export default function Home() {
  const [displayedText, setDisplayedText] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackClass, setFeedbackClass] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fullText = "Welcome to Alpha Construction Training";

  useEffect(() => {
    let index = 0;
    const typingInterval = setInterval(() => {
      if (index <= fullText.length) {
        setDisplayedText(fullText.substring(0, index));
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      message: e.target.message.value,
    };

    try {
      const response = await fetch(
        "https://www.alphacontraining.co.uk/sendcontactemail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (response.ok) {
        setFeedbackMessage("Your message has been sent successfully!");
        setFeedbackClass("success");
      } else {
        setFeedbackMessage(`Failed to send message: ${result.error}`);
        setFeedbackClass("error");
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFeedbackMessage("There was an error. Please try again.");
      setFeedbackClass("error");
      setIsSubmitting(false);
    }
  };
  return (
    <div className="App">
      <Helmet>
        <title>Home | Alpha Constructions</title>
        <meta
          name="description"
          content="Alpha Constructions offers expert guidance and training for CPCS, NPORS, and NVQ certifications. Reach out to us to build your skills and advance your career with trusted qualifications in construction. Connect with us via email, phone, social media, or our contact form."
        />
      </Helmet>
      <Navbar />
      <div className="hero">
        <h1>{displayedText}</h1>
      </div>

      <div className="intro-section">
        <div className="intro-content">
          <img src={whoAreWe} alt="Who Are We" className="intro-image" />
          <div className="intro-text">
            <h2>Who Are We?</h2>
            <p>
              At Alpha Construction Training, we pride ourselves on delivering
              top-notch training and certifications for the construction
              industry. Our mission is to empower individuals with the skills,
              knowledge, and qualifications needed to excel in their careers.
              From CPCS and NPORS to NVQ certifications, we ensure quality
              training tailored to your needs.
            </p>
          </div>
        </div>
      </div>

      <div className="cards-wrapper">
        <div className="cards-container">
          <div className="cards">
            <div className="card">
              <img src={cpcs} alt="CPCS card information" />
              <div className="card-content">
                <h2>CPCS</h2>
                <p>
                  Provides essential certifications for operators of plant
                  machinery, ensuring safety and compliance in construction.
                </p>
                <a href="/cpcs">
                  <button>Learn More</button>
                </a>
              </div>
            </div>
            <div className="card">
              <img src={npors} alt="NPORS card information" />
              <div className="card-content">
                <h2>NPORS</h2>
                <p>
                  An alternative to CPCS, focusing on flexibility and quality in
                  machinery operation training and accreditation.
                </p>
                <a href="/npors">
                  <button>Learn More</button>
                </a>
              </div>
            </div>
            <div className="card">
              <img src={nvq} alt="NVQ card information" />
              <div className="card-content">
                <h2>NVQ</h2>
                <p>
                  Recognizes on-the-job skills and knowledge across various
                  construction roles, qualifying professionals for career
                  advancement.
                </p>
                <a href="/nvq">
                  <button>Learn More</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-cards-wrapper">
        <div className="contact-cards-container">
          <div className="contact-cards">
            <div className="contact-card">
              <div className="contact-card-content">
                <h2>Contact Us</h2>
                <div className="cpcs-contact-details">
                  <p>Email: info@alphacontraining.co.uk</p>
                  <p>Phone: +44 7475 510 591</p>
                </div>
                <div className="cpcs-social-media">
                  <h2>Social Media</h2>
                  <p>Check us out on social media</p>
                  <div className="cpcs-social-icons">
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-facebook"></i>
                    </a>
                    <a
                      href="https://twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-twitter"></i>
                    </a>
                    <a
                      href="https://instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-card">
              <div className="contact-card-content">
                <h2>Leave a Message</h2>
                {feedbackMessage && (
                  <p className={`feedback-message ${feedbackClass}`}>
                    {feedbackMessage}
                  </p>
                )}
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your full name"
                    required
                  />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    required
                  />
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Enter your phone number"
                    required
                  />
                  <textarea
                    id="message"
                    name="message"
                    rows="5"
                    placeholder="Enter your message"
                    required
                  ></textarea>
                  <button
                    type="submit"
                    className="submit-button"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Message Sent! " : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
